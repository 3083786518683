<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div class="mb-2 ml-3 mr-2">
        <router-link to="/admin/notification/new">
        <el-button type="primary" size="small"
          >Back</el-button
        >
        </router-link>
      </div>
      <div class="mb-2" v-if="usersSelected.length > 0">
        <el-button type="primary" size="small" @click="newNotification()"
          >Send Notification</el-button
        >
      </div>
    </div>
    <el-table
      :data="
        this.tableData.filter(
          (data) =>
            !search ||
            data.name.toLowerCase().includes(search.toLowerCase()) ||
            data.birthDay.toLowerCase().includes(search.toLowerCase()) ||
            data.phone.toLowerCase().includes(search.toLowerCase()) ||
            data.email.toLowerCase().includes(search.toLowerCase())
        )
      "
      class="w-100"
    >
      <el-table-column width="40">
        <template slot="header">
          <input
            class="form-check-input"
            type="checkbox"
            v-on:change="selectAllOnTable()"
            v-model="selectAllTable"
          />
        </template>
        <template slot-scope="scope">
          <div class="form-check">
            <input
              class="form-check-input"
              v-model="scope.row.selected"
              v-on:change="updateSelection()"
              type="checkbox"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column prop="phone" label="Phone"></el-table-column>
      <el-table-column sortable prop="birthDay" label="Birthday">
        <template slot-scope="scope">{{ scope.row.birthDay | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column label="Operations">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            placeholder="Search..."
            class="p-0"
            :key="scope.row"
          />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              icon="el-icon-bell"
              :disabled="usersSelected.length > 0"
              @click="newNotification(scope.row)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      title="New Notification"
      :visible.sync="component.drawer"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-new-notification
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import shareholder from "@/services/api/shareholder";
import ComponentNewNotification from "@/components/admin/NewNotification";
export default {
  components: {
    ComponentNewNotification,
  },
  data() {
    return {
      selectAllTable: false,
      usersSelected: [],
      tableData: [],
      data: [],
      search: "",
      component: {
        data: null,
        drawer: false,
        render: 0,
        index: null,
      },
    };
  },
  mounted() {
    shareholder.userShareholdersByOffice(this.$route.params.office).then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
      response.forEach((user) => {
        user.selected = false;
      });
    });
  },
  methods: {
    selectAllOnTable() {
      let users = Object.assign([], this.data);
      if (this.selectAllTable) {
        users.forEach((element) => {
          if (element.selected === false) {
            element.selected = true;
          }
        });
      } else {
        users.forEach((element) => {
          element.selected = false;
        });
      }

      this.tableData = Object.assign([], users);
      this.updateSelection();
    },
    newNotification(row = null) {
      let users = [];
      if (row) {
        users.push({ id: row.id, name: row.name });
      } else {
        this.usersSelected.forEach((user) =>
          users.push({ id: user.id, name: user.name })
        );
      }
      this.$swal({
        title: "New Notification",
        text: "You will create a new notification for " + users.map(item=>item.name).join(", "),
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      }).then((result) => {
        if(result.value) this.add({ users: users });
        return
      });
    },
    updateSelection() {
      this.usersSelected = this.tableData.filter(
        (user) => user.selected === true
      );
    },
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add(data = null) {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = data ? data : null;
    },
  },
};
</script>
